import { ResultsYouCanFeel } from '@/app/components/platform';
import CountUp from './CountUp';
import Conditions from './Conditions';
import LogosColumns from './LogosColumns';
import SideImageIcons from './SideImageIcons';
import Testimonials from './Testimonials';
import ThreeColumnsCards from './ThreeColumnsCards';

const COMPONENTS = {
  'platform.conditions': Conditions,
  'platform.count-up': CountUp,
  'platform.logos': LogosColumns,
  'platform.results': ResultsYouCanFeel,
  'platform.side-image': SideImageIcons,
  'platform.testimonials': Testimonials,
  'platform.three-cols': ThreeColumnsCards,
};

const getComponent = (componentKey) => {
  if (!COMPONENTS[componentKey]) {
    console.error(`Component ${componentKey} doesn't exist.`);
    return false;
  }

  return COMPONENTS[componentKey];
};

export default function DynamicComponent({ body }) {
  if (!body) {
    console.error("Please provide a 'body' property to the DynamicComponent");
    return;
  }

  return (
    <div id="dynamic-zone">
      {body.map((bodyItem) => {
        const Component = getComponent(bodyItem['__component']);
        if (!Component) {
          return '';
        }
        return <Component key={`${bodyItem['__component']}-${bodyItem.id}`} content={bodyItem} />;
      })}
    </div>
  );
}
